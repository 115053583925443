import React from 'react';
import shapeOne from '../../assets/images/60301606666d12e9208349ae_Bg-Developer-Sm.svg';
import hero from "../../assets/images/top_header_image_new.jpg";

function HeroHomeEight() {
    return (
      <>
        <section className="appie-hero-area appie-hero-8-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="appie-hero-content appie-hero-content-8">
                  <h1 className="appie-title">Surf Smarter with LookSmart</h1>
                  <p>
                    Smartbase develops a range of products and services across
                    <br />
                    mobile and desktop platforms.
                  </p>

                  <ul>
                    <li>
                     {/* <a
                        className="item-2"
                        href="https://play.google.com/store/apps/details?id=com.smartbase"
                      >
                        Get Started <i class="fal fa-arrow-right"></i>
    </a>*/}
                      <a
                      className="main-btn"
                      href="https://play.google.com/store/apps/details?id=com.smartbase"
                    >
                      <i className="fab fa-google-play" />
                      Download for Android
                    </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="appie-hero-thumb-6">
                  <div
                    className="thumb text-center wow animated fadeInUp"
                    data-wow-duration="1000ms"
                    data-wow-delay="600ms"
                  >
                    <img src={hero} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-8-shape-3">
            <img src={shapeOne} alt="" />
          </div>
        </section>
      </>
    );
}

export default HeroHomeEight;
