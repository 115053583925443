import { Link } from 'react-router-dom';
import shapeOne from '../../assets/images/60301606666d12e9208349ae_Bg-Developer-Sm.svg';
import hero from "../../assets/images/top_header_image_new.jpg";

function HeroHomeEight() {
    return (
      <>
        <section className="appie-hero-area appie-hero-8-area hui-app">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="appie-hero-content appie-hero-content-8">
                  <h1 className="appie-title">Enhance your Browser</h1> 
                  <p>
                    SmartBase replaces your current New Tab with search
                    <br />
                    and productivity features right at your fingertips!
                  </p>

                  <ul>
                    <li>
                     {/* <a
                        className="item-2"
                        href="https://play.google.com/store/apps/details?id=com.smartbase"
                      >
                        Get Started <i class="fal fa-arrow-right"></i>
    </a>*/}
                      <a
                      className="main-btn"
                      href="https://chrome.google.com/webstore/detail/smartbase-by-looksmart/hijkamckfelgdjkjanhpjbcidfaagpbf?hl=en"
                    >
                   
                      Install
                    </a>
                    </li>
                  </ul>
                  <p className='iuy'>By clicking "Install", I accept and agree to installing the SmartBase Extension and setting the default search to that provided by the service.The search features within the extension will be provided by Microsoft Bing. I also accept the  <Link to="/privacy">Privacy Policy</Link> and the <Link to="/terms">Terms</Link> of Use.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="appie-hero-thumb-6">
                  <div
                    className="thumb text-center wow animated fadeInUp"
                    data-wow-duration="1000ms"
                    data-wow-delay="600ms"
                  >
                    <img src={hero} alt="" />
                  </div>
                </div>
              </div>
            </div>
             
          <div className="home-8-shape-3">
            <img src={shapeOne} alt="" />
          </div>
         
          </div>
          <div className='leftinfo'>
            <p className='jio'>
                               <a href="mailto:support@looksmart.com" class="email">Email</a>
                                  </p><p>
                                310-596-1990<br/>2850 W Horizon Ridge Pkwy, Ste 200<br/>Henderson, Nevada, 89052
                                </p> 
          </div>
          <div className='jiu'><ul>
                    
                    <li>
                      
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                      
                      <Link to="/terms">Terms</Link>
                    </li>

                    <li>
                      
                      <Link to="/aboutUs">About Us</Link>
                    </li>
                    <li>
                      
                      <Link to="/contactUs">Contact Us</Link>
                    </li>
                  </ul></div>
        </section>
      </>
    );
}

export default HeroHomeEight;
